"use client";

import { GlobalDrawer, Loader, Theme } from "@cleanlist-ai/portal/components";
import { persistor, store } from "@cleanlist-ai/portal/store";
import { cn } from "@cleanlist-ai/portal/utils";
import { ClerkProvider } from "@clerk/nextjs";
import { Analytics } from "@vercel/analytics/react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { inter, lato } from "./fonts";
import "./globals.css";
import { CSPostHogProvider } from "./posthog";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en" dir="ltr">
      <head>
        <title>Cleanlist Portal</title>
      </head>
      <CSPostHogProvider>
        <ClerkProvider>
          <body
            className={cn(
              inter.variable,
              lato.variable,
              "font-inter",
              "font-lato"
            )}
          >
            <Provider {...{ store: store }}>
              <PersistGate loading={<Loader />} {...{ persistor: persistor }}>
                <Theme />
                <Toaster />
                <GlobalDrawer />
                {children}
              </PersistGate>
              <Analytics />
            </Provider>
          </body>
        </ClerkProvider>
      </CSPostHogProvider>
    </html>
  );
}
