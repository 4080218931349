"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  posthog.init(
    process.env.NEXT_PUBLIC_POSTHOG_KEY ??
      "phc_ep6CWqynJI1MiOXNKWLnCTpgEiBkhO9n6KSJpy8Pbjz",
    {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://app.posthog.com",
    }
  );
}

type ProviderProps = {
  children: React.ReactNode;
};

export const CSPostHogProvider = ({ children }: ProviderProps) => {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
